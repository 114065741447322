// keep this at the top of the stylesheet
@use 'sass:math';

@import 'fonts';

html {
  font-size: math.div(100vh * 50, 1080);
}

html,
body {
  margin: 0;
  padding: 0;
}
body {
  font-family: 'Sailec-Regular', sans-serif;
}
